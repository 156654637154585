@font-face {
  font-family: "BogartLight";
  src: local("Bogart-Light"),
    url("./library/fonts/Bogart-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Bogart";
  src: local("Bogart-Regular"),
    url("./library/fonts/Bogart-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "BogartMedium";
  src: local("Bogart-Medium"),
    url("./library/fonts/Bogart-Medium.ttf") format("truetype");
  font-display: swap;
}

@keyframes echo {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

h1,
h2,
h3,
img {
  -moz-transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
  -webkit-transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: fit-content;
}

body {
  width: 100%;
  margin: 0;
  font-family: "Bogart", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #a2a8ab;
}
